<template>
  <div class="switch-setter" :class="{ disabled: !enabled }">
    <div class="title">
      <span>{{ onLang(data.title) }}</span>
      <TooltipElement :content="onLang(data.tooltip)" alwaysShow placement="bottom">
        <img src="@/assets/icons/Info_24.svg" alt="">
      </TooltipElement>
    </div>
    <div class="switch">
      <div 
        v-for="item in data.options" 
        :key="item.value" 
        class="switch-item"
        :class="{ active: item.value === value }"
        @click="selectValue(item.value)"
      >
        {{ onLang(item.label) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchSetter',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue')
  },
  props: {
    data: Object,
    value: {
      type: [ Number, String, Boolean ],
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  },
  methods: {
    selectValue(value) {
      if (this.enabled) {
        this.$emit('input', value)
      }
    },
    onLang(str) {
      if (str && String(str).includes('$t_')) {
        return this.$t(str.replace('$t_', ''))
      } else {
        return str
      }
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  user-select: none;
}
.switch-setter {
  background: #39425D;
  border-radius: 8px;
  padding: 8px 12px;
  position: relative;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin-bottom: 8px;
    span {
      font-size: px2rem(16);
      line-height: 24px;
      color: #ffffff;
    }
    img {
      width: 20px;
      height: 20px;
      @include filter_FFF_50;
      cursor: pointer;
    }
  }

  .switch {
    display: flex;
    .switch-item {
      flex: 1;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: px2rem(16);
      line-height: 24px;
      border: 1px solid #ffffff80;
      cursor: pointer;
      &.active {
        background: #FFC600;
        border-color: #FFC600;
        color: #282942;
      }
      &:nth-child(1) {
        border-right: none;
        border-radius: 8px 0px 0px 8px;
      }
      &:nth-child(2) {
        border-left: none;
        border-radius: 0px 8px 8px 0px;
      }
    }
  }
}
</style>